import Vue from "vue";

Vue.component("search", {
  data: function () {
    return {
      keyword: "",
      results: "",
    };
  },
  methods: {
    searchFood: function () {
      let keyword = this.keyword;
      if (keyword.length >= 1) {
        fetch(`${window.location.protocol}/api/name/${keyword}`, {
          method: "GET", // or 'POST'
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
        })
          .then((response) => response.json())
          .then((totalResults) => {
            this.results = totalResults.data;
          });
      } else {
        this.results = "";
      }
    },
  },
  template: `
        <div>
            <div class="form-group">
                <input type="text" class="form-control" v-model="keyword" @keyup="searchFood" placeholder="... search for">
            </div>
            <div v-for="result in results">
                <div class="media">
                    <div class="dot" v-bind:class="'dot--' + result.grade"></div> 
                    <div class="media-body">
                        <p class="food-item"><a v-bind:href="result._id">{{result.name}} - {{result.category}}</a></p>
                    </div>                   
                </div>
            </div>
        </div>
        `,
});
