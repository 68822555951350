import './modules/search';
import './modules/foodElement';

const searchId = document.getElementById('searching');
const foodElement = document.getElementById('foodElement');

if (searchId) {
    new Vue({
        el: '#searching'
    });
}

if (foodElement) {
    new Vue({
        el: "#foodElement"
    });
}

