Vue.component('food-element', {
    data: function () {
        return {
            dataLoaded: false, //show loading message until the request is not getting the data
            results: ""
        }
    },
    props: {
        name: {
            type: String,
            required: true,
            validator: function (value) {
                // The value must match one of these strings
                return ['oligos', 'fructose', 'polyols', 'lactose'].indexOf(value) !== -1
            }
        },
        validity: {
            type: String,
            required: true,
            validator: function (value) {
                // The value must match one of these strings
                return ['true', 'false'].indexOf(value) !== -1
            }
        }
    },
    methods: {
        getUrl: function () {
            let sysUrl = window.location.href;
            let splittedUrl = sysUrl.split("/");
            let url = `${splittedUrl[0]}//${splittedUrl[2]}/api/${this.name}?param=${this.validity}`;
            return url;
        },
        getData: function () {
            fetch(this.getUrl())
                .then(res => res.json())
                .then(res => {
                    this.dataLoaded = true; //data is loaded so data div can be visible in the page
                    this.results = res.data;
                })
        }
    },
    created() {
        this.getData();
    },
    template: `    
        <div>
            <div class="loading" v-if="!dataLoaded"><h1>Loading data ...</h1></div>
            <div v-else>
                <table class="table table-hover table-responsive-md">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Food name</th>
                            <th scope="col">Category</th>
                            <th scope="col">
                                <ul class="list-inline" style="margin-bottom: 0;">                    
                                    <li class="list-inline-item"> O </li>
                                    <li class="list-inline-item"> F </li>
                                    <li class="list-inline-item"> P </li>
                                    <li class="list-inline-item"> L </li>
                                </ul>
                            </th>
                        </tr>
                    </thead>
                    <tbody  v-for="(result, index) in results" :key="result.id">
                        <tr>
                            <td scope="row">{{index + 1}}</td>
                            <td><a v-bind:href="result._id">{{result.name}}</a></td>
                            <td>{{result.category}}</td>
                            <td>
                                <ul class="list-inline">                    
                                    <li class="list-inline-item dot" v-bind:class="'dot--' + result.oligos"></li>
                                    <li class="list-inline-item dot" v-bind:class="'dot--' + result.fructose"></li>
                                    <li class="list-inline-item dot" v-bind:class="'dot--' + result.polyols"></li>
                                    <li class="list-inline-item dot" v-bind:class="'dot--' + result.lactose"></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>        
        </div>       
    `
});
